<template>
  <admin>
    <metatag title="Test Drives"></metatag>
    <page-header>
      <template v-slot:action>
        <v-row>
          <v-col cols="6">
            <v-btn :to="{ name: 'test-drives-upload' }" :block="$vuetify.breakpoint.smAndDown" large color="info" exact>
              <v-icon small left>mdi-cloud-upload</v-icon>
              <span v-text="'Upload'"></span>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn :block="$vuetify.breakpoint.smAndDown" large color="grey" class="white--text" exact
              @click="exportTestDrive">
              <v-icon small left>mdi-cloud-download</v-icon>
              <span v-text="'Download'"></span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </page-header>

    <v-row v-if="userDetails">
      <v-col>
        <v-card>
          <toolbar-menu class="testdrives-toolbar" :filter-model="true" :filter-status="true" :filter-action="true"
            @update:search="setSearch" @update:model="setModelFilter" @update:status="setStatusFilter"
            @update:action="setActionFilter" :items.sync="tabletoolbar"></toolbar-menu>

          <v-card-text class="pa-0">
            <v-data-table :headers="resources.headers" :items="items" :loading="loading" :options.sync="options"
              :items-per-page="5" :server-items-length="total" color="primary" item-key="id" class="text-no-wrap">
              <template v-slot:progress><span></span></template>

              <template v-slot:loading>
                <v-slide-y-transition mode="out-in">
                  <skeleton-avatar-table></skeleton-avatar-table>
                </v-slide-y-transition>
              </template>

              <template v-slot:item.cust_number="{ item }">
                <small># {{ item.customer_id }}</small> <br />
              </template>

              <template v-slot:item.updated_at="{ item }">
                <small>{{ formatDate(item.updated_at, "YYYY-MM-DD H:m:s") }}</small> <br />
              </template>

              <template v-slot:item.test_drive_date="{ item }">
                <small>{{ formatDate(item.test_drive_date, "YYYY-MM-DD") }}</small> <br />
              </template>

              <template v-slot:item.full_name="{ item }">
                <small>{{ item.title }}. {{ item.first_name }} {{ item.last_name }}</small> <br />
                <small>{{ item.mobile }}</small> <br />
                <small>{{ item.email }}</small>
              </template>

              <template v-slot:item.vehicle="{ item }">
                <small>{{ item.model_name }}</small> <br />
                <small>{{ item.variant_name }}</small> <br />
                <small>{{ item.color_name }}</small>
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip label>
                  {{ item.status ? item.status.label : "" }}
                </v-chip>
              </template>

              <template v-slot:item.action="{ item }">
                <v-chip label>
                  {{ item.action ? item.action.label : "" }}
                </v-chip>
              </template>

              <template v-slot:item.id="{ item }">
                <div class="text-no-wrap">
                  <!-- Preview -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="previewItem(item)" text icon color="primary" v-on="on">
                        {{ item.id }}
                      </v-btn>
                    </template>
                    <span v-text="'Preview'"></span>
                  </v-tooltip>
                  <!-- Preview -->
                </div>
              </template>

              <template v-slot:item.editAction="{ item }">
                <div class="text-no-wrap">
                  <!-- Edit -->
                  <can :code="['dealer', 'sales_consultant']">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="editItem(item)" text icon v-on="on">
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Edit'"></span>
                    </v-tooltip>
                  </can>
                  <!-- Edit -->
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <can :code="['dealer', 'sales_consultant']">
      <v-dialog v-model="actionDialog" max-width="600px" class="overflow-hidden">
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Edit" class="mb-1">Edit</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <v-row>
              <v-col cols="12">
                <SalesConsultant :assigned_sc_user_id="formUpdate.assigned_sc_user_id" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select append-icon="mdi-chevron-down" :items="statusList" item-text="text" item-value="value"
                  :value="formUpdate.status" label="Status" outlined clearable hide-details
                  clear-icon="mdi-close-circle-outline" background-color="selects"
                  @change="editStatus($event)"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select append-icon="mdi-chevron-down" :items="actionList" item-text="text" item-value="value"
                  :value="formUpdate.action" label="Action" outlined clearable hide-details
                  clear-icon="mdi-close-circle-outline" background-color="selects"
                  @change="editAction($event)"></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="grey" exact class="ma-1 white--text px-5" @click="actionDialog = false">
              Cancel
            </v-btn>

            <v-btn :loading="submitLoading" :disabled="submitLoading" large exact color="green darken-1"
              class="ma-1 white--text px-5" @click="update()">
              <v-icon left>mdi-content-save</v-icon>
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </can>

    <template v-if="previewDialog">
      <v-dialog v-model="previewDialog" max-width="900px" class="overflow-hidden">
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Edit" class="mb-1">#{{ item.id }}</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <!-- Background Details -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="font-weight-bold">{{ "Customer" }}</td>
                    <td>{{ item.title + " " + item.first_name + " " + item.last_name }}</td>
                    <td class="font-weight-bold">{{ "Date or Birth" }}</td>
                    <td>{{ item.birthdate }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Province" }}</td>
                    <td>{{ item.province }}</td>
                    <td class="font-weight-bold">{{ "Municipality" }}</td>
                    <td>{{ item.municipality }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Barangay" }}</td>
                    <td>{{ item.barangay }}</td>
                    <td class="font-weight-bold">{{ "Zip Code" }}</td>
                    <td>{{ item.zipcode }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Street" }}</td>
                    <td>{{ item.street }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Email" }}</td>
                    <td>{{ item.email }}</td>
                    <td class="font-weight-bold">{{ "Mobile" }}</td>
                    <td>{{ item.mobile }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Model" }}</td>
                    <td>{{ item.model_name }}</td>
                    <td class="font-weight-bold">{{ "Variant" }}</td>
                    <td>{{ item.variant_name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Color" }}</td>
                    <td>{{ item.color_name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Test Drive Date" }}</td>
                    <td>{{ item.test_drive_date }}</td>
                    <td class="font-weight-bold">{{ "Sales Consultant" }}</td>
                    <td>{{ item.sales_consultant }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Status" }}</td>
                    <td>
                      <v-chip label> {{ item.status ? item.status.label : "" }} </v-chip>
                    </td>
                    <td class="font-weight-bold">{{ "Action" }}</td>
                    <td>
                      <v-chip label> {{ item.action ? item.action.label : "" }} </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Assigned Date" }}</td>
                    <td>{{ item.sc_assigned_at }}</td>
                    <td class="font-weight-bold">{{ "Updated By" }}</td>
                    <td>{{ item.updated_by }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">{{ "Date Created" }}</td>
                    <td>{{ item.created_at }}</td>
                    <td class="font-weight-bold">{{ "Date Updated" }}</td>
                    <td>{{ item.updated_at }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ "Preferred Communication" }}
                    </td>
                    <td>{{ item.preferred_communication }}</td>
                    <td class="font-weight-bold">
                      {{ "Preferred Dealer" }}
                    </td>
                    <td>{{ item.dealer_code }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- Background Details -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="grey" exact class="ma-1 white--text px-5" @click="previewDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-if="uploadDialog">
      <v-dialog v-model="uploadDialog" max-width="1200px" class="overflow-hidden">
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Edit" class="mb-1">Upload</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <v-btn @click="onPickFile" block large color="info" exact>
              <v-icon small left>mdi-cloud-upload</v-icon>
              <span v-text="'Import Excel'"></span>
            </v-btn>
            <input type="file" style="display: none" ref="fileInput" accept=".csv, 
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
            application/vnd.ms-excel" @change="onFilePicked" />
          </v-card-text>
          <v-card-text class="overflow-y-auto text-center">
            {{ file.name }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="primary" exact class="ma-1 white--text px-5" @click="uploadTestDrives">
              Upload
            </v-btn>
            <v-btn large color="grey" exact class="ma-1 white--text px-5" @click="openUploadDialog(false)">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SalesConsultant from "@/components/SalesConsultant/SalesConsultant";
import { debounce } from "lodash";
import * as helpers from "@/core/helpers";

export default {
  data() {
    return {
      actionDialog: false,
      previewDialog: false,
      uploadDialog: false,
      submitLoading: false,
      statusList: [],
      actionList: [],
      resources: {
        loading: true,
        headers: [
          { text: "ID", align: "left", value: "id", class: "text-no-wrap" },
          { text: "Cust. #", align: "left", value: "customer_id", class: "text-no-wrap" },
          { text: "Name", align: "left", value: "full_name", class: "text-no-wrap" },
          { text: "Test Drive Date", value: "test_drive_date", class: "text-no-wrap" },
          { text: "Vehicle", value: "vehicle", class: "text-no-wrap" },
          { text: "Preferred Dealer", value: "dealer_code", class: "text-no-wrap" },
          { text: "Sales Consultant", value: "sales_consultant", class: "text-no-wrap" },
          { text: "SC Assigned Date", value: "sc_assigned_at", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap" },
          { text: "Action", value: "action", class: "text-no-wrap" },
          { text: "Date Updated", value: "updated_at", class: "text-no-wrap" },
        ],
      },
      tabletoolbar: {
        isSearching: false,
        search: null,
        status: [],
        model: [],
        action: [],
        type: "testdrive",
      },
      loading: true,
      total: 0,
      items: [],
      item: null,
      options: {},
      userDetails: null,
      filters: {
        model: null,
        status: null,
        action: null,
        q: null,
      },
      formUpdate: {
        assigned_sc_user_id: 0,
      },
      file: {},
    };
  },
  components: {
    SalesConsultant,
  },
  computed: {
    ...mapGetters({
      data: "testDrives/GET_TEST_DRIVES",
      models: "testDrives/GET_MODELS",
      actions: "testDrives/GET_ACTIONS",
      statuses: "testDrives/GET_STATUSES",
      salesConsultant: "report/GET_SALES_CONSULTANT",
      userRoles: "auth/GET_ROLES"
    }),
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(page, itemsPerPage, this.filters);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getTestDrives: "testDrives/getTestDrives",
      updateTestDrive: "testDrives/updateTestDrive",
      setSalesConsultant: "report/setSalesConsultant",
      importTestDrives: "testDrives/importTestDrives",
      getStatuses: "testDrives/getStatuses",
      getActions: "testDrives/getActions",
      downloadTestDrive: "testDrives/downloadTestDrive",
    }),
    showTip(e) {
      document.querySelector(".tooltip-message").classList.remove("d-none");
    },
    formatDate(item, format) {
      return helpers.format_date(new Date(item), format);
    },
    previewItem(item) {
      this.item = item;
      this.previewDialog = true;
    },
    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.filters.q = e.target.value;
      this.getItems(1, itemsPerPage, this.filters);
    }, 300),

    setModelFilter: debounce(async function (val) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.filters.model = val;
      this.getItems(1, itemsPerPage, this.filters);
    }, 300),

    setActionFilter: debounce(async function (val) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.filters.action = val;
      this.getItems(1, itemsPerPage, this.filters);
    }, 300),

    setStatusFilter: debounce(async function (val) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.filters.status = val;
      this.getItems(1, itemsPerPage, this.filters);
    }, 300),

    getItems(page, itemsPerPage, filters = {}) {
      this.getTestDrives({
        page: page,
        per_page: itemsPerPage,
        filters: filters,
      }).then(() => {
        this.items = this.data.data;
        this.total = this.data.meta.total;
        this.loading = false;
        this.tabletoolbar.isSearching = false;
      });
    },

    // Update Section

    editItem(item) {
      this.actionDialog = true;
      this.formUpdate.assigned_sc_user_id = item.assigned_sc_user_id;
      this.formUpdate.status = item.status ? item.status.code : "";
      this.formUpdate.action = item.action ? item.action.code : "";
      this.formUpdate.id = item.id;
    },
    editAction(e) {
      this.formUpdate.action = e;
    },
    editStatus(e) {
      this.formUpdate.status = e;
    },
    update() {
      this.formUpdate.assigned_sc_user_id = this.salesConsultant;
      this.submitLoading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.updateTestDrive(this.formUpdate).then((data) => {
        this.submitLoading = false;

        if (data.success === true) {
          this.getItems(page, itemsPerPage, this.filters);
          this.actionDialog = false;
        }
      });
    },
    openUploadDialog(val) {
      this.uploadDialog = val;
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      this.file = files[0];
    },
    uploadTestDrives() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var formData = new FormData();

      formData.append("file", this.file);

      this.importTestDrives(formData).then((data) => {
        if (data.success === true) {
          this.getItems(page, itemsPerPage, this.filters);
          this.file = {};
        }
      });
    },
    getUpdateFormData() {
      this.getStatuses().then(() => {
        for (var i = 0; i < this.statuses.length; i++) {
          this.statusList.push({
            text: this.statuses[i].title,
            value: this.statuses[i].slug,
          });
        }
      });

      this.getActions().then(() => {
        for (var i = 0; i < this.actions.length; i++) {
          this.actionList.push({
            text: this.actions[i].title,
            value: this.actions[i].slug,
          });
        }
      });
    },
    async exportTestDrive() {
      await this.downloadTestDrive();
    },
  },
  created() {
    if (!localStorage.user) {
      this.$router.push({ name: "login" });
    } else {
      this.userDetails = JSON.parse(localStorage.user);

      this.getUpdateFormData();
    }

    let user_roles = this.userRoles.map(a => a.slug);

    let isFound = ['dealer', 'sales_consultant'].some(ai => user_roles.includes(ai));

    if (isFound) {
      this.resources.headers = [
        ...this.resources.headers,
        {
          text: "HCPI",
          value: "hcpi",
          text: "Actions",
          align: "center",
          value: "editAction",
          class: "muted--text text-no-wrap",
        },
      ];
    }
  },
};
</script>

<style></style>
